import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import LoginRightPane from "./LoginRightPane";

@inject("session")
@observer
class LoginRightPaneContainer extends Component {
  render() {
    const { onChangeEmail, onChangePwd, login, loginEnter } = this.props.session;
    return (
      <React.Fragment>
        <LoginRightPane
          onChangeEmail={onChangeEmail}
          onChangePwd={onChangePwd}
          login={login}
          loginEnter={loginEnter}
        />
      </React.Fragment>
    );
  }
}

export default LoginRightPaneContainer;
