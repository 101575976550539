import React from 'react';
import './ErrorInfo.scss';
import { Link } from 'react-router-dom';
const ErrorInfo = ({ code, message }) => {
  return (
    <div className="ErrorInfo">
      <div className="error-code">{code}</div>
      <div className="error-message">{message}</div>
      <div className="error-home">
        <Link to="/">
          <button>홈으로</button>
        </Link>
      </div>
    </div>
  );
};

export default ErrorInfo;
