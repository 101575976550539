import React from 'react';
import './InputTags.scss';
import { observer } from 'mobx-react';
import { IoIosRemoveCircle } from 'react-icons/io';
const InputTags = ({ tagList, onChangeTagText, onInsertTag, onDeleteTag }) => {
  return (
    <div className="InputTags">
      <div className="input-tag">
        <input placeholder="태그 입력 후 Enter" onChange={e => onChangeTagText(e.target.value)} onKeyUp={onInsertTag} />
      </div>
      <div className="tags">
        {tagList.map(tag => (
          <div className="tag" key={tag}>
            <div className="tag-text">{tag}</div>
            <div className="tag-remove">
              <IoIosRemoveCircle onClick={() => onDeleteTag(tag)} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default observer(InputTags);
