import React, { Component } from 'react';
import { SubmitBox } from '.';
import { inject, observer } from 'mobx-react';

@inject('editor')
@observer
class SubmitBoxContainer extends Component {
  render() {
    const { editor } = this.props;
    return (
      <React.Fragment>
        <SubmitBox
          onSubmit={editor.isEdit ? editor.onClickUpdate : editor.onClickSave}
          isSubmitBox={editor.getIsSubmitBox}
        />
      </React.Fragment>
    );
  }
}

export default SubmitBoxContainer;
