import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Root from './Root';
import { Provider } from 'mobx-react';
import EditorStore from 'stores/editor';
import MainStore from 'stores/main';
import SessionStore from 'stores/session';
import BaseStore from 'stores/base';
import UserStore from 'stores/user';
import PostStore from 'stores/post';
import SettingStore from 'stores/setting';
import TagStore from 'stores/tag';

const editor = new EditorStore();
const main = new MainStore();
const session = new SessionStore();
const base = new BaseStore();
const user = new UserStore();
const post = new PostStore();
const setting = new SettingStore();
const tag = new TagStore();

ReactDOM.render(
  <Provider
    editor={editor}
    main={main}
    session={session}
    base={base}
    user={user}
    post={post}
    setting={setting}
    tag={tag}
  >
    <Root />
  </Provider>,
  document.getElementById('vrware-blog')
);
