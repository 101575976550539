import { observable, action, computed } from "mobx";

export default class BaseStore {
  @observable isUserMenu = false;

  @action
  showUserMenu = () => {
    this.isUserMenu = !this.isUserMenu;
  };

  @computed
  get getThumbnail() {
    if (localStorage.getItem("globe_user")) {
      return JSON.parse(localStorage.getItem("globe_user")).thumbnail;
    } else {
      return null;
    }
  }
}
