import React, { Component } from 'react';
import { UserTemplate } from 'components/user/UserTemplate';
import { Helmet } from 'react-helmet';
import { HeaderContainer } from 'components/base/Header';
import { UserInfoContainer } from '../components/user/UserInfo';
import { UserPostListContainer } from '../components/user/UserPostList';

class User extends Component {
  render() {
    const { match } = this.props;
    return (
      <UserTemplate header={<HeaderContainer />}>
        <Helmet>
          <title>{`@${match.params.nickname} | VRWARE Blog`}</title>
        </Helmet>
        <UserInfoContainer nickname={match.params.nickname} />
        <UserPostListContainer nickname={match.params.nickname} />
      </UserTemplate>
    );
  }
}

export default User;
