import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import TagList from './TagList';

@inject('tag')
@observer
class TagListContainer extends Component {
  componentDidMount() {
    const { tag } = this.props;
    tag.loadTagList();
  }

  render() {
    const { tag } = this.props;
    return (
      <React.Fragment>
        <TagList tagList={tag.getTagList} />
      </React.Fragment>
    );
  }
}

export default TagListContainer;
