import "./EditorButton.scss";
import React from "react";

const EditorButton = ({ text, onClick }) => {
  return (
    <button className="editor-button" onClick={onClick}>
      {text}
    </button>
  );
};

export default EditorButton;
