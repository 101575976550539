import React from "react";
import "./HeaderRight.scss";
import { UserCircleContainer } from "components/base/UserCircle";
import { UserMenuContainer } from "components/base/UserMenu";

const HeaderRight = () => {
  return (
    <React.Fragment>
      <UserCircleContainer />
      <UserMenuContainer />
    </React.Fragment>
  );
};

export default HeaderRight;
