import React from "react";
import "./MainTemplate.scss";

const MainTemplate = ({ header, children }) => {
  return (
    <div className="MainTemplate">
      {header}
      <main>{children}</main>
    </div>
  );
};

export default MainTemplate;
