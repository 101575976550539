import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderRight } from 'components/base/HeaderRight';
import './Header.scss';
const Header = ({ isSession }) => {
  return (
    <header className='Header'>
      <div className='header-wrap'>
        <Link to='/' className='logo'>
          VRWARE Blog
        </Link>
        <div className='header-right'>{isSession && <HeaderRight />}</div>
      </div>
    </header>
  );
};

export default Header;
