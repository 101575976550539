import React from 'react';
import { Link } from 'react-router-dom';
import './PostTags.scss';
const PostTags = ({ tagList }) => {
  if (!tagList[0]) return null;
  return (
    <div className='PostTags'>
      {tagList.map(tag => (
        <Link key={tag} to={`/tags/${tag.replace(/ +/g, '-')}`}>
          {tag}
        </Link>
      ))}
    </div>
  );
};

export default PostTags;
