import React from "react";
import "./LoginRightPane.scss";

const LoginRightPane = ({ onChangeEmail, onChangePwd, login, loginEnter }) => {
  return (
    <div className="LoginRightPane">
      <div className="right">
        <div className="login-box">
          <h2 className="login-title">{"로그인"}</h2>
          <div className="login-email">
            <input
              type="text"
              onChange={e => onChangeEmail(e.target.value)}
              onKeyPress={loginEnter}
              placeholder={"이메일을 입력해주세요."}
            />
          </div>
          <div className="login-pwd">
            <input
              type="password"
              onChange={e => onChangePwd(e.target.value)}
              onKeyPress={loginEnter}
              placeholder={"비밀번호를 입력해주세요."}
            />
          </div>
          <div className="login-button-box">
            <button className="login-button" onClick={login}>
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginRightPane;
