import React from 'react';
import { Link } from 'react-router-dom';
import './PostHeader.scss';
import { dateFormat } from 'lib/common/dateFormat';

const PostHeader = ({ postInfo, userNickname, onDelete }) => {
  return (
    <div className='PostHeader'>
      <h1 className='title'>{postInfo.title}</h1>
      <div className='post-info'>
        <span className='nickname'>
          <Link to={`/@${postInfo.nickname}`}>by {postInfo.nickname}</Link>
        </span>
        <div className='date'>{dateFormat(postInfo.modDate, 'MMM DD, YYYY')}</div>
      </div>
      <div className='line' />
      {postInfo.nickname === userNickname ? (
        <div className='post-action-wrap'>
          <Link className='post-edit' to={`/editor?post_id=${postInfo.postId}`}>
            수정
          </Link>
          <button className='post-delete-btn' onClick={onDelete}>
            삭제
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default PostHeader;
