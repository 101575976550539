import React, { Component } from 'react';
import PostList from './PostList';
import { inject, observer } from 'mobx-react';

@inject('main')
@observer
class PostListContainer extends Component {
  componentDidMount() {
    const { main } = this.props;
    if (localStorage.getItem('globe_user')) {
      main.getPosts();
    }
  }

  render() {
    const { main } = this.props;
    return (
      <React.Fragment>
        <PostList postList={main.getPostList} isLoading={main.isLoading} />
      </React.Fragment>
    );
  }
}

export default PostListContainer;
