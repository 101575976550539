import { observable, action, computed } from 'mobx';
import { BlogApi, VrwareApi } from '../lib/api';

export default class UserStore {
  @observable userPostList = [];
  @observable userPostCount = 0;
  @observable userThumbnail = null;

  @action
  setUserPostList = nickname => {
    if (!nickname) return;
    this.setUserThumbnail(nickname);
    BlogApi.get(`/posts?nickname=${nickname}`)
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.userPostList = [...res.data.data.postList];
            this.userPostCount = res.data.data.postList.length;
          }
        })
      )
      .catch(
        action(err => {
          if (err.response.data.status_code >= 400) {
            this.userPostList = [];
            this.userPostCount = 0;
          }
        })
      );
  };

  @action
  setUserThumbnail = nickname => {
    if (!nickname) return;
    VrwareApi.get(`/members?nickname=${nickname}`)
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.userThumbnail = res.data.data.memberInfo[0].thumbnail;
          }
        })
      )
      .catch(
        action(err => {
          if (err.response.data.status_code >= 400) {
            this.userThumbnail = null;
            window.location = '/404';
          }
        })
      );
  };

  @computed
  get getUserPostCount() {
    return this.userPostCount;
  }

  @computed
  get getUserPostList() {
    return this.userPostList;
  }

  @computed
  get getUserThumbnail() {
    return this.userThumbnail;
  }
}
