import React from 'react';
import './PostContent.scss';

import { MarkdownRender } from 'components/common/MarkdownRender';

const PostContent = ({ postInfo }) => {
  return (
    <div className='PostContent'>
      {postInfo.thumbnail ? (
        <div className='thumbnail'>
          <img src={postInfo.thumbnail} alt={''} />
        </div>
      ) : null}
      <MarkdownRender markdown={postInfo.content} />
    </div>
  );
};

export default PostContent;
