import React from "react";
import { PostItem } from "../PostItem";
import "./PostList.scss";

const PostList = ({ postList, isLoading }) => {
  if (isLoading) return null;

  return (
    <ul className="PostList">
      {postList.map(post => (
        <PostItem key={post.postLink} post={post} />
      ))}
    </ul>
  );
};

export default PostList;
