import React from 'react';
import './PostComments.scss';
import { PostCommentInputContainer } from '../PostCommentInput';
import { PostCommentListContainer } from '../PostCommentList';
const PostComments = ({ commentCount }) => {
  return (
    <div className='PostComments'>
      <h3>{commentCount}개의 댓글</h3>
      <PostCommentInputContainer />
      <PostCommentListContainer />
    </div>
  );
};

export default PostComments;
