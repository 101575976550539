import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { EditorPreview } from ".";
@inject("editor")
@observer
class EditorPreviewContainer extends Component {
  render() {
    const { editor } = this.props;
    return (
      <React.Fragment>
        <EditorPreview title={editor.title} markdown={editor.markdown} />
      </React.Fragment>
    );
  }
}

export default EditorPreviewContainer;
