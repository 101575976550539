import React, { Component } from 'react';
import PostCommentInput from './PostCommentInput';
import { inject, observer } from 'mobx-react';

@inject('post')
@observer
class PostCommentInputContainer extends Component {
  render() {
    const {
      post,
      isCommentEdit,
      defaultValue,
      onToggleCommentEdit,
      onClickCommentUpdate,
      onChangeCommentEditContent
    } = this.props;
    return (
      <React.Fragment>
        <PostCommentInput
          isCommentEdit={isCommentEdit}
          defaultValue={defaultValue}
          onClickCommentSave={post.onClickCommentSave}
          onClickCommentUpdate={onClickCommentUpdate}
          onChangeCommentContent={post.onChangeCommentContent}
          onChangeCommentEditContent={onChangeCommentEditContent}
          onToggleCommentEdit={onToggleCommentEdit}
        ></PostCommentInput>
      </React.Fragment>
    );
  }
}

export default PostCommentInputContainer;
