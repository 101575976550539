import React, { Component } from 'react';
import { PostLikes } from '.';
import { inject, observer } from 'mobx-react';

@inject('post')
@observer
class PostLikesContainer extends Component {
  pos = 0;

  state = {
    isFixed: false
  };

  onScroll = () => {
    let postLikes = document.getElementsByClassName('PostLikes')[0];
    this.pos = postLikes.getBoundingClientRect().top + document.documentElement.scrollTop;
    const isFixed = document.documentElement.scrollTop >= this.pos;
    if (this.state.isFixed !== isFixed) {
      this.setState({ isFixed: isFixed });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  render() {
    const { post } = this.props;
    return (
      <React.Fragment>
        <PostLikes
          isLike={post.getIsLike}
          isFixed={this.state.isFixed}
          likeCount={post.getLikeCount}
          onClickPostLike={post.onClickPostLike}
        />
      </React.Fragment>
    );
  }
}

export default PostLikesContainer;
