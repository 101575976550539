import React, { Component } from 'react';
import UserInfo from './UserInfo';
import { inject, observer } from 'mobx-react';

@inject('user', 'base')
@observer
class UserInfoContainer extends Component {
  render() {
    const { user, nickname } = this.props;
    return (
      <React.Fragment>
        <UserInfo
          nickname={nickname}
          postCount={user.getUserPostCount}
          thumbnail={user.getUserThumbnail}
        />
      </React.Fragment>
    );
  }
}

export default UserInfoContainer;
