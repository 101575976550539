import React from 'react';
import './EditorPreview.scss';

import { MarkdownRender } from 'components/common/MarkdownRender';

const EditorPreview = ({ title, markdown }) => {
  return (
    <div className="EditorPreview" id="editor-preview">
      <h1 className="preview-title">{title}</h1>
      <div>
        <MarkdownRender markdown={markdown} />
      </div>
    </div>
  );
};

export default EditorPreview;
