import React from 'react';

import './PostTemplate.scss';
import { PostContentContainer } from '../PostContent';
import { PostHeaderContainer } from '../PostHeader';
import { PostCommentsContainer } from '../PostComments';
import { PostTagsContainer } from '../PostTags';
import { PostLikesContainer } from '../PostLikes';

const PostTemplate = ({ isLoading }) => {
  if (isLoading) return null;
  return (
    <div className='PostTemplate'>
      <PostLikesContainer />
      <PostHeaderContainer />
      <PostContentContainer />
      <PostTagsContainer />
      <PostCommentsContainer />
    </div>
  );
};

export default PostTemplate;
