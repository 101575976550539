import React from 'react';
import './PostCommentInput.scss';
import TextareaAutosize from 'react-autosize-textarea';
const PostCommentInput = ({
  isCommentEdit,
  defaultValue,
  onClickCommentSave,
  onClickCommentUpdate,
  onChangeCommentContent,
  onChangeCommentEditContent,
  onToggleCommentEdit
}) => {
  return (
    <div className='PostCommentInput'>
      <TextareaAutosize
        id='comment-input'
        onChange={e => {
          isCommentEdit
            ? onChangeCommentEditContent(e.target.value)
            : onChangeCommentContent(e.target.value);
        }}
        defaultValue={defaultValue}
      />
      <div className='comment-btn'>
        {isCommentEdit ? (
          <React.Fragment>
            <button onClick={onClickCommentUpdate}>수정하기</button>
            <button className='comment-edit-cancel' onClick={onToggleCommentEdit}>
              취소
            </button>
          </React.Fragment>
        ) : (
          <button onClick={onClickCommentSave}>댓글 작성</button>
        )}
      </div>
    </div>
  );
};

export default PostCommentInput;
