import { observable, action, computed } from 'mobx';
import { BlogApi } from 'lib/api';

export default class PostStore {
  @observable postInfo = [];
  @observable commentList = [];
  @observable commentCount = 0;
  @observable isLoading = true;
  @observable isLike = false;
  @observable likeCount = 0;
  @observable commentContent = null;

  @action
  onChangeCommentContent = commentContent => {
    this.commentContent = commentContent;
  };

  @action
  onDelete = () => {
    const { postId } = this.postInfo[0];
    if (!postId) return;
    BlogApi.delete(`/posts/${postId}`)
      .then(res => {
        if (res.data.status_code === 200) {
          return window.history.back();
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          return alert('게시글 삭제를 실패하였습니다.');
        }
      });
  };

  @action
  onClickCommentSave = () => {
    if (!this.commentContent) return;
    if (!localStorage.getItem('globe_user')) return;

    const { postId } = this.postInfo[0];
    const nickname = JSON.parse(localStorage.getItem('globe_user')).nickname;

    BlogApi.post(`/posts/${postId}/comments`, {
      postId: postId,
      nickname: nickname,
      content: this.commentContent
    })
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            document.getElementById('comment-input').value = '';
            this.loadCommentInfo();
          }
        })
      )
      .catch(
        action(err => {
          if (err.response.data.status_code >= 400) {
            return alert(err.response.data.alert_message);
          }
        })
      );
  };

  @action
  onClickCommentUpdate = (commentId, commentEditContent) => {
    if (!commentId || !commentEditContent) return;

    const { postId } = this.postInfo[0];

    BlogApi.patch(`/posts/${postId}/comments/${commentId}`, { content: commentEditContent })
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.loadCommentInfo();
          }
        })
      )
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onClickCommentDelete = commentId => {
    if (!commentId || !this.postInfo[0]) return;
    const { postId } = this.postInfo[0];
    BlogApi.delete(`/posts/${postId}/comments/${commentId}`)
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.loadCommentInfo();
            // window.location.reload();
          }
        })
      )
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onClickPostLike = () => {
    if (!localStorage.getItem('globe_user')) return;
    const { postId } = this.postInfo[0];
    const nickname = JSON.parse(localStorage.getItem('globe_user')).nickname;
    let likeApi;

    //좋아요 등록
    if (!this.isLike) {
      likeApi = BlogApi.post(`/posts/${postId}/likes`, { nickname: nickname });
      this.likeCount += 1;
    } else if (this.isLike) {
      //좋아요 취소
      likeApi = BlogApi.delete(`/posts/${postId}/likes/${nickname}`);
      this.likeCount -= 1;
    }

    likeApi
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.isLike = !this.isLike;
          }
        })
      )
      .catch(
        action(err => {
          if (err.response.data.status_code >= 400) {
            return alert(err.response.data.alert_message);
          }
        })
      );
  };

  @action
  loadCommentInfo = () => {
    BlogApi.get(`/posts/${this.postInfo[0].postId}/comments`)
      .then(
        action(_res => {
          if (_res.data.status_code === 200) {
            this.commentList = _res.data.data.commentList;
            this.commentCount = _res.data.data.commentList.length;
          }
        })
      )
      .catch(
        action(err => {
          if (err.response.data.status_code >= 400) {
            this.commentList = 0;
            this.commentCount = 0;
          }
        })
      );
  };

  @action
  loadPostInfo = (nickname, postLink, userNickname) => {
    this.isLoading = true;
    BlogApi.get(`/posts?nickname=${nickname}&postLink=${postLink}`)
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.postInfo = res.data.data.postList;
            this.isLoading = false;

            const isLike = res.data.data.postList[0].likes.find(
              like => like.nickname === userNickname
            );

            //로그인한 유저가 이 글에 좋아요를 눌렀다면
            if (isLike) {
              this.isLike = true;
            } else {
              //아니라면
              this.isLike = false;
            }

            this.likeCount = res.data.data.postList[0].likes.length;

            if (this.postInfo[0].comments !== []) {
              this.loadCommentInfo();
            }
          }
        })
      )
      .catch(
        action(err => {
          if (err.response.data.status_code >= 400) {
            this.postInfo = [];
            this.isLoading = false;
          }
        })
      );
  };

  @computed
  get getPostInfo() {
    return this.postInfo;
  }

  @computed
  get getCommentList() {
    return this.commentList;
  }

  @computed
  get getCommentCount() {
    return this.commentCount;
  }

  @computed
  get getLikeCount() {
    return this.likeCount;
  }

  @computed
  get getIsLike() {
    return this.isLike;
  }
}
