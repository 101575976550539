import React from 'react';
import './PostCommentList.scss';
import { PostCommentItem } from '../PostCommentItem';
const PostCommentList = ({
  commentList,
  userNickname,
  onClickCommentDelete,
  onClickCommentUpdate
}) => {
  if (!commentList.length) return null;
  return (
    <div className='PostCommentList'>
      {commentList.map(commentInfo => (
        <PostCommentItem
          key={commentInfo.commentId}
          commentInfo={commentInfo}
          userNickname={userNickname}
          onClickCommentDelete={onClickCommentDelete}
          onClickCommentUpdate={onClickCommentUpdate}
        ></PostCommentItem>
      ))}
    </div>
  );
};

export default PostCommentList;
