import React from 'react';
import { Link } from 'react-router-dom';
import './TagItem.scss';

const TagItem = ({ tagName, postCount }) => {
  return (
    <Link className='TagItem' to={`/tags/${tagName}`}>
      <div className='tag-name'>{tagName}</div>
      <div className='post-count'>{postCount}</div>
    </Link>
  );
};

export default TagItem;
