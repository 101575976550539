import React, { Component } from 'react';
import PostCommentList from './PostCommentList';
import { inject, observer } from 'mobx-react';

@inject('post', 'session')
@observer
class PostCommentListContainer extends Component {
  render() {
    const { post, session } = this.props;
    const userNickname = session.getNickname;
    return (
      <React.Fragment>
        <PostCommentList
          commentList={post.getCommentList}
          userNickname={userNickname}
          onClickCommentDelete={post.onClickCommentDelete}
          onClickCommentUpdate={post.onClickCommentUpdate}
        ></PostCommentList>
      </React.Fragment>
    );
  }
}

export default PostCommentListContainer;
