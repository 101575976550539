import { observable, action, computed } from 'mobx';
import { VrwareApi, AuthApi } from 'lib/api';

export default class SessionStore {
  @observable email = '';
  @observable password = '';
  @observable nickname = '';
  @observable isSession = true;

  @action
  onChangeEmail = email => {
    if (this.email !== email) {
      this.email = email;
    }
  };

  @action
  onChangePwd = pwd => {
    if (this.password !== pwd) {
      this.password = pwd;
    }
  };

  @action
  loginEnter = e => {
    if (e.key === 'Enter') {
      this.login();
    }
  };

  @action
  login = () => {
    const { email, password } = this;

    VrwareApi.get(`/admins?adminEmail=${email}`)
      .then(
        action(adminInfo => {
          if (adminInfo.data.status_code === 200) {
            return AuthApi.post(
              '/sessions',
              { email: email, password: password },
              { withCredentials: true }
            );
          }
        })
      )
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            //로그인 성공 후 세션 확인
            this.loadSession();
          }
        })
      )
      .catch(
        action(err => {
          if (err.response.data.status_code >= 400) {
            this.isSession = false;
            alert(err.response.data.alert_message);
          }
        })
      );
  };

  @action
  Logout = () => {
    AuthApi.delete('/sessions', {
      withCredentials: true
    })
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            localStorage.removeItem('globe_user');
            this.isSession = false;
          }
        })
      )
      .catch(err => {
        localStorage.removeItem('globe_user');
        this.isSession = false;
      });
  };

  @action setNickname = nickname => {
    this.nickname = nickname;
  };

  @action
  loadSession = () => {
    let email;
    AuthApi.get('/sessions/cookies', { withCredentials: true })
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            email = JSON.parse(res.data.data.sessionInfo[0].session).email;
            return VrwareApi.get(`/admins?adminEmail=${email}`);
          }
        })
      )
      .then(
        action(adminInfo => {
          if (adminInfo.data.status_code === 200) {
            return VrwareApi.get(`/members?email=${email}`);
          }
        })
      )
      .then(memberInfo => {
        this.setNickname(memberInfo.data.data.memberInfo[0].nickname);
        const globeUser = {
          nickname: memberInfo.data.data.memberInfo[0].nickname,
          email: memberInfo.data.data.memberInfo[0].email,
          thumbnail: memberInfo.data.data.memberInfo[0].thumbnail
        };
        localStorage.setItem('globe_user', JSON.stringify(globeUser));
        this.isSession = true;
      })
      .catch(
        action(err => {
          if (err.response.data.status_code >= 400) {
            this.isSession = false;
          }
        })
      );
  };

  @computed
  get getNickname() {
    if (localStorage.getItem('globe_user')) {
      return JSON.parse(localStorage.getItem('globe_user')).nickname;
    } else {
      return this.nickname;
    }
  }
}
