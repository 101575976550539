import React from 'react';
import './LoginTemplate.scss';
import { LoginLeftPane } from '../LoginLeftPane';
import { LoginRightPaneContainer } from '../LoginRightPane';

const LoginTemplate = () => {
  return (
    <div className='LoginTemplate'>
      <LoginLeftPane
        headLine={'우리들만의 소통 공간'}
        subLine={'VRWARE Blog를 통해 서로의 지식을 공유하여 소통하고 성장해요.'}
      />
      <LoginRightPaneContainer />
    </div>
  );
};

export default LoginTemplate;
