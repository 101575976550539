import React, { Component } from 'react';
import TagPostList from './TagPostList';
import { inject, observer } from 'mobx-react';

@inject('tag')
@observer
class TagPostListContainer extends Component {
  componentDidMount() {
    const { tag, tagName } = this.props;
    tag.loadTagPostList(tagName);
  }

  render() {
    const { tag } = this.props;
    return (
      <React.Fragment>
        <TagPostList tagPostList={tag.getTagPostList} />
      </React.Fragment>
    );
  }
}

export default TagPostListContainer;
