import React, { Component } from 'react';
import { Header } from '.';
import { inject, observer } from 'mobx-react';

@inject('session')
@observer
class HeaderContainer extends Component {
  render() {
    const { session } = this.props;
    return (
      <React.Fragment>
        <Header isSession={session.isSession}></Header>
      </React.Fragment>
    );
  }
}

export default HeaderContainer;
