import React from 'react';
import { PageTemplate } from '../components/base/PageTemplate';
import { PostTemplateContainer } from 'components/post/PostTemplate';
import { HeaderContainer } from '../components/base/Header';

const Post = ({ match }) => {
  return (
    <PageTemplate header={<HeaderContainer />}>
      <PostTemplateContainer params={match.params} />
    </PageTemplate>
  );
};

export default Post;
