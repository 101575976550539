import React from 'react';
import { TagTemplate } from 'components/tags/TagTemplate';
import { HeaderContainer } from '../components/base/Header';
import { TagListContainer } from '../components/tags/TagList';
import { TagPostListContainer } from '../components/tags/TagPostList';
import { Helmet } from 'react-helmet';

const Tags = ({ match }) => {
  const { tagName } = match.params;

  return (
    <TagTemplate header={<HeaderContainer />}>
      {tagName ? (
        <React.Fragment>
          <Helmet>
            <title>#{tagName} | VRWARE Blog</title>
          </Helmet>
          <TagPostListContainer tagName={tagName} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Helmet>
            <title>태그 목록 | VRWARE Blog</title>
          </Helmet>
          <TagListContainer />
        </React.Fragment>
      )}
    </TagTemplate>
  );
};

export default Tags;
