import React from "react";
import "./UserTemplate.scss";

const UserTemplate = ({ header, children }) => {
  return (
    <div className="UserTemplate">
      {header}
      <main>{children}</main>
    </div>
  );
};

export default UserTemplate;
