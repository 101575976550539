import React from 'react';
import { TagItem } from '../TagItem';
import './TagList.scss';
const TagList = ({ tagList }) => {
  if (!tagList) return null;
  return (
    <div className='TagList'>
      {tagList.map(tagInfo => (
        <TagItem tagName={tagInfo.tagName} postCount={tagInfo.postCount} />
      ))}
    </div>
  );
};

export default TagList;
