import React from 'react';
import './LoginLeftPane.scss';

const LoginLeftPane = ({ headLine, subLine }) => {
  return (
    <div className='LoginLeftPane'>
      <div className='left'>
        <div>
          <div className='logo'>VRWARE Blog</div>
          <h2>{headLine}</h2>
          <p>{subLine}</p>
        </div>
      </div>
    </div>
  );
};

export default LoginLeftPane;
