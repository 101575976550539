import React from "react";

import { LoginTemplateContainer } from "../components/login/LoginTemplate";
import { MainTemplateContainer } from "../components/main/MainTemplate";

const Home = () => {
  return (
    <React.Fragment>
      <LoginTemplateContainer />
      <MainTemplateContainer />
    </React.Fragment>
  );
};

export default Home;
