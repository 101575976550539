import React, { Component } from 'react';
import PostContent from './PostContent';
import { inject, observer } from 'mobx-react';

@inject('post')
@observer
class PostContentContainer extends Component {
  render() {
    const { post } = this.props;
    const postInfo = post.getPostInfo[0];
    return (
      <React.Fragment>
        <PostContent postInfo={postInfo}></PostContent>
      </React.Fragment>
    );
  }
}

export default PostContentContainer;
