import React, { Component } from 'react';
import UploadThumbnail from './UploadThumbnail';
import { inject, observer } from 'mobx-react';

@inject('editor')
@observer
class UploadThumbnailContainer extends Component {
  render() {
    const { editor } = this.props;
    return (
      <React.Fragment>
        <UploadThumbnail
          thumbnail={editor.getThumbnail}
          onUploadThumbnail={editor.onUploadThumbnail}
          onDeleteThumbnail={editor.onDeleteThumbnail}
        />
      </React.Fragment>
    );
  }
}

export default UploadThumbnailContainer;
