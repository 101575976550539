import React, { Component } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { Home, Editor, Post, User, Tags } from 'pages';
import { Helmet } from 'react-helmet';
import { inject, observer } from 'mobx-react';
import history from 'lib/common/history';
import { Settings } from '../pages';
import { NotFound } from '../pages/errors';

@inject('session')
@observer
class App extends Component {
  componentDidMount() {
    const { session } = this.props;
    session.loadSession();
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>VRWARE Blog</title>
          <meta name='description' content='우리들만의 소통공간, VRWARE Blog' />
          <meta name='twitter:card' content='summary' />
          <meta name='twitter:title' content='우리들만의 소통공간, VRWARE Blog' />
          <meta
            name='twitter:description'
            content='VRWARE Blog를 통해 서로의 지식을 공유하여 소통하고 성장해요.'
          />
          <meta name='twitter:site' content='https://blog.vrware.us' />
          <meta property='og:title' content='우리들만의 소통공간, VRWARE Blog' />
          <meta property='og:site_name' content='VRWARE Blog' />
          <meta property='og:type' content='website' />
          <meta property='og:url' content='https://blog.vrware.us' />
          {/*  <meta property="og:image" content="./images/vrware.png"> */}
          <meta
            property='og:description'
            content='VRWARE Blog를 통해 서로의 지식을 공유하여 소통하고 성장해요.'
          />
        </Helmet>
        <Router history={history}>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/editor' component={Editor} />
            <Route path='/@:nickname/:postLink' component={Post} />
            <Route path='/@:nickname' component={User} />
            <Route path='/settings' component={Settings} />
            <Route exact path='/tags' component={Tags} />
            <Route exact path='/tags/:tagName' component={Tags} />
            <Route path='/404' component={NotFound} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
