import React from "react";
import defaultThumbnail from "images/base/defaultThumbnail.png";
import "./UserCircle.scss";

const UserCircle = ({ thumbnail, onClick }) => {
  return (
    <div className="UserCircle" onClick={onClick}>
      <img src={thumbnail || defaultThumbnail} alt="user_thumbnail" />
    </div>
  );
};

export default UserCircle;
