import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import LoginTemplate from "./LoginTemplate";

@inject("session")
@observer
class LoginTemplateContainer extends Component {
  render() {
    const { isSession } = this.props.session;
    if (isSession) return null;
    return (
      <React.Fragment>
        <LoginTemplate />
      </React.Fragment>
    );
  }
}

export default LoginTemplateContainer;
