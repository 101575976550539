import React, { Component } from "react";
import { SettingProfile } from ".";
import { inject, observer } from "mobx-react";

@inject("base", "session", "setting")
@observer
class SettingProfileContainer extends Component {
  render() {
    const { session, setting, base } = this.props;
    return (
      <React.Fragment>
        <SettingProfile
          nickname={session.getNickname}
          thumbnail={base.getThumbnail}
          onUploadThumbnail={setting.onUploadThumbnail}
        />
      </React.Fragment>
    );
  }
}

export default SettingProfileContainer;
