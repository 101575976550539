import React from 'react';
import { Helmet } from 'react-helmet';
import { ErrorInfo } from '../../components/etc/ErrorInfo';

const NotFound = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Not Found Page | VRWARE Blog</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ErrorInfo code={404} message={'페이지가 존재하지 않습니다.'}></ErrorInfo>
    </React.Fragment>
  );
};

export default NotFound;
