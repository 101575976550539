import { action } from 'mobx';
import { VrwareApi } from 'lib/api';
import loadImage from 'blueimp-load-image';

export default class SettingStore {
  @action
  onUploadThumbnail = (files) => {
    if (!localStorage.getItem('globe_user')) return;

    loadImage(
      files[0],
      (img, data) => {
        img.toBlob(async (blob) => {
          const rotateFile = new File([blob], files[0].name, { type: files[0].type });
          const data = new FormData();
          data.append('file', rotateFile);
          data.append('filename', files[0].name);

          const email = JSON.parse(localStorage.getItem('globe_user')).email;

          try {
            const res = await VrwareApi.post(`/v1.1/members/${email}/thumbnails`, data);
            if (res.data.status_code === 200) {
              const globeUser = JSON.parse(localStorage.getItem('globe_user'));
              globeUser.thumbnail = res.data.data.imgPath;
              localStorage.setItem('globe_user', JSON.stringify(globeUser));
              window.location.reload();
            }
          } catch (error) {
            if (error.response.data.status_code >= 400) {
              return alert(error.response.data.alert_message);
            }
          }
        }, files[0].type);
      },
      { orientation: true },
    );
  };
}
