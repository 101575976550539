import React, { Component } from 'react';
import { InputTags } from '.';
import { inject, observer } from 'mobx-react';

@inject('editor')
@observer
class InputTagsContainer extends Component {
  render() {
    const { editor } = this.props;
    const tagList = editor.getTags;
    return (
      <React.Fragment>
        <InputTags
          tagList={tagList}
          onChangeTagText={editor.onChangeTagText}
          onInsertTag={editor.onInsertTag}
          onDeleteTag={editor.onDeleteTag}
        />
      </React.Fragment>
    );
  }
}

export default InputTagsContainer;
