import React from 'react';
import './EditorUploadMask.scss';
import { FaCloudUploadAlt } from 'react-icons/fa';
const EditorUploadMask = ({ isUploadMask }) => {
  if (!isUploadMask) return null;
  return (
    <div className='EditorUploadMask'>
      <FaCloudUploadAlt />
      <h3>파일을 드래그하여 업로드 하세요</h3>
    </div>
  );
};

export default EditorUploadMask;
