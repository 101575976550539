import React, { Component } from 'react';
import { PostHeader } from '.';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

@inject('post', 'session')
@observer
class PostHeaderContainer extends Component {
  render() {
    const { post, session } = this.props;
    const userNickname = session.getNickname;
    const postInfo = post.getPostInfo[0];
    return (
      <React.Fragment>
        <Helmet>
          <title>{`${postInfo.title} | VRWARE Blog`}</title>
          <meta name='description' content={postInfo.description} />
          <meta property='og:title' content={postInfo.title} />
          <meta property='og:image' content={postInfo.thumbnail || ''} />
          <meta property='og:description' content={postInfo.description} />
        </Helmet>
        <PostHeader
          postInfo={postInfo}
          userNickname={userNickname}
          onDelete={post.onDelete}
        ></PostHeader>
      </React.Fragment>
    );
  }
}

export default PostHeaderContainer;
