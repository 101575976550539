import React from 'react';
import { Link } from 'react-router-dom';
import './TagPostItem.scss';
import { dateFormat } from 'lib/common/dateFormat';

const TagPostItem = ({ post }) => {
  return (
    <li className={`TagPostItem ${post.thumbnail ? '' : 'noThumb'}`}>
      {post.thumbnail ? (
        <Link className='thumbnail' to={`/@${post.nickname}/${post.postLink}`}>
          <img src={post.thumbnail} alt={post.title} />
        </Link>
      ) : null}
      <div className='post-content'>
        <h3 className='title'>
          <Link to={`/@${post.nickname}/${post.postLink}`}>{post.title}</Link>
        </h3>
      </div>
      <div className='post-info'>
        <div className='nickname'>
          <Link to={`/@${post.nickname}`}>by {post.nickname}</Link>
        </div>
        <div className='date'>{dateFormat(post.modDate, 'MMM DD, YYYY')}</div>
        <div className='comments'>{post.comments.length}개의 댓글</div>
      </div>
      <div className='line' />
      <div className='post-bottom'>
        <div className='description'>{post.description}</div>
      </div>
    </li>
  );
};

export default TagPostItem;
