import { action, observable, computed } from 'mobx';
import { BlogApi } from 'lib/api';

export default class TagStore {
  @observable tagList = [];
  @observable tagPostList = [];

  @action
  loadTagList = () => {
    BlogApi.get('/tags')
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.tagList = res.data.data.tagList;
          }
        })
      )
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          this.tagList = [];
        }
      });
  };

  @action
  loadTagPostList = tagName => {
    BlogApi.get(`/tags?tagName=${tagName}`)
      .then(
        action(res => {
          if (res.data.status_code === 200) {
            this.tagPostList = res.data.data.tagPostList;
          }
        })
      )
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          this.tagPostList = [];
        }
      });
  };

  @computed
  get getTagList() {
    return this.tagList;
  }

  @computed
  get getTagPostList() {
    return this.tagPostList;
  }
}
