import React, { Component } from 'react';
import './CodeEditor.scss';
import CodeMirror from 'codemirror';

import 'codemirror/lib/codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/scroll/simplescrollbars.css';
import 'codemirror/addon/display/placeholder';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/jsx/jsx';
import 'codemirror/mode/css/css';
import 'codemirror/mode/shell/shell';
import 'codemirror/theme/monokai.css';

class CodeEditor extends Component {
  editor = null;
  codeMirror = null;

  initializeEditor = () => {
    const { onChangeMarkdown, isEdit, markdown, onScroll, onDragEnter, onDragLeave } = this.props;
    this.codeMirror = CodeMirror(this.editor, {
      mode: 'markdown',
      theme: 'monokai',
      lineNumbers: false,
      lineWrapping: true,
      scrollbarStyle: 'overlay',
      placeholder: '내용을 입력해주세요.'
    });
    this.codeMirror.on('change', onChangeMarkdown);
    this.codeMirror.on('scroll', onScroll);
    this.codeMirror.on('dragenter', (event, e) => onDragEnter(e));
    this.codeMirror.on('dragleave', (event, e) => onDragLeave(e));

    if (isEdit) {
      this.codeMirror.setValue(markdown);
    }
  };

  componentDidMount() {
    this.initializeEditor();
  }

  insertText = () => {
    const { insertText, onClearInsertText } = this.props;
    const editor = this.codeMirror;
    const selection = editor.getSelection();

    if (selection.length > 0) {
      editor.replaceSelection(insertText);
    } else {
      const doc = editor.getDoc();
      const cursor = doc.getCursor();

      const pos = {
        line: cursor.line,
        ch: cursor.ch
      };

      doc.replaceRange(insertText, pos);
    }
    onClearInsertText();
  };

  componentDidUpdate(prevProps, prevState) {
    const { markdown, cursor } = this.props;
    if (prevProps.markdown !== markdown) {
      const { codeMirror } = this;

      if (!codeMirror) {
        return;
      }
      //값이 같지 않을때만 마크다운을 코드미러에 반영
      if (codeMirror.getValue() !== markdown) {
        codeMirror.setValue(markdown);
      }

      if (!cursor) {
        return;
      }

      if (codeMirror.getCursor() !== cursor) {
        codeMirror.setCursor(cursor);
      }
    }

    if (!prevProps.insertText && this.props.insertText) {
      this.insertText();
    }
  }

  render() {
    const { onChangeTitle, title } = this.props;
    return (
      <div className='CodeEditor'>
        <input
          className='title'
          placeholder='제목을 입력하세요.'
          name='title'
          onChange={e => onChangeTitle(e.target.value)}
          defaultValue={title || undefined}
          autoComplete='off'
        />
        <div className='code-editor' ref={ref => (this.editor = ref)} />
      </div>
    );
  }
}

export default CodeEditor;
