import React from "react";
import "./SettingProfile.scss";
import ReactFileReader from "react-file-reader";
import defaultThumbnail from "images/base/defaultThumbnail.png";
const SettingProfile = ({ thumbnail, nickname, onUploadThumbnail }) => {
  return (
    <div className="SettingProfile">
      <div className="thumbnail-wrap">
        <img src={thumbnail || defaultThumbnail} alt="thumbnail" />
        <ReactFileReader handleFiles={onUploadThumbnail}>
          <button className="change-thumbnail-btn">썸네일 변경</button>
        </ReactFileReader>
      </div>
      <div className="user-info">
        <h2 className="nickname">{nickname}</h2>
      </div>
    </div>
  );
};

export default SettingProfile;
