import React from "react";
import "./EditorTemplate.scss";

const EditorTemplate = ({ header, children }) => {
  return (
    <div className="EditorTemplate">
      {header}
      <main>{children}</main>
    </div>
  );
};

export default EditorTemplate;
