import React from 'react';
import './SubmitBox.scss';
import { InputTagsContainer } from '../InputTags';
import { UploadThumbnailContainer } from '../UploadThumbnail';
const SubmitBox = ({ isSubmitBox, onSubmit }) => {
  if (!isSubmitBox) return null;
  return (
    <div className='SubmitBox'>
      <section className='option-wrap'>
        <section className='option-section'>
          <div className='option-title'>공개 설정</div>
          <div>개발 중</div>
        </section>
        <section className='option-section'>
          <div className='option-title'>태그 편집</div>
          <InputTagsContainer />
        </section>
        <section className='option-section'>
          <div className='option-title'>썸네일</div>
          <UploadThumbnailContainer />
        </section>
        <div className='post-submit'>
          <button onClick={onSubmit}>완료</button>
        </div>
      </section>
    </div>
  );
};

export default SubmitBox;
