import React, { Component } from 'react';
import UserPostList from './UserPostList';
import { inject, observer } from 'mobx-react';

@inject('user', 'session')
@observer
class UserPostListContainer extends Component {
  componentDidMount() {
    const { user, nickname } = this.props;
    user.setUserPostList(nickname);
  }

  componentDidUpdate(prevProps, prevState) {
    const { user, nickname } = this.props;
    if (prevProps.nickname !== nickname) {
      user.setUserPostList(nickname);
    }
  }

  render() {
    const { user, nickname } = this.props;
    return (
      <React.Fragment>
        <UserPostList userPostList={user.getUserPostList} nickname={nickname} />
      </React.Fragment>
    );
  }
}

export default UserPostListContainer;
