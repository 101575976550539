import React, { Component } from 'react';
import { PostComments } from '.';
import { inject, observer } from 'mobx-react';

@inject('post')
@observer
class PostCommentsContainer extends Component {
  render() {
    const { post } = this.props;
    return (
      <React.Fragment>
        <PostComments commentCount={post.getCommentCount}></PostComments>
      </React.Fragment>
    );
  }
}

export default PostCommentsContainer;
