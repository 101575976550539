import React, { Component } from "react";
import UserMenu from "./UserMenu";
import { inject, observer } from "mobx-react";

@inject("base", "session", "editor")
@observer
class UserMenuContainer extends Component {
  render() {
    const { isUserMenu, showUserMenu } = this.props.base;
    const { Logout, getNickname } = this.props.session;
    const { onClickNewEditor } = this.props.editor;
    let nickname = getNickname;

    if (!isUserMenu) return null;

    return (
      <React.Fragment>
        <UserMenu
          nickname={nickname}
          onClick={showUserMenu}
          onLogout={Logout}
          onClickNewEditor={onClickNewEditor}
        />
      </React.Fragment>
    );
  }
}

export default UserMenuContainer;
