import React, { Component } from 'react';
import { PostTemplate } from '.';
import { inject, observer } from 'mobx-react';

@inject('session', 'post', 'editor')
@observer
class PostTemplateContainer extends Component {
  componentDidMount() {
    const { post, session, params } = this.props;
    post.loadPostInfo(params.nickname, params.postLink, session.getNickname);
  }

  render() {
    const { post } = this.props;
    return (
      <React.Fragment>
        <PostTemplate isLoading={post.isLoading} />
      </React.Fragment>
    );
  }
}
export default PostTemplateContainer;
