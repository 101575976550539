import React from "react";
import { Link } from "react-router-dom";
import { dateFormat } from "lib/common/dateFormat";
import "./UserPostItem.scss";
const UserPostItem = ({ post }) => {
  return (
    <li className="UserPostItem">
      <Link to={`/@${post.nickname}/${post.postLink}`}>
        <strong className="title">{post.title}</strong>
      </Link>
      <div className="description-wrap">
        <span className="description">{post.description}</span>
      </div>
      <div className="date-wrap">
        <span className="date">{dateFormat(post.modDate, "MMM DD, YYYY")}</span>
      </div>
    </li>
  );
};

export default UserPostItem;
