import React from "react";
import "./UserInfo.scss";
import defaultThumbnail from "images/base/defaultThumbnail.png";
const UserInfo = ({ thumbnail, nickname, postCount }) => {
  return (
    <div className="UserInfo">
      <div className="thumbnail">
        <img src={thumbnail || defaultThumbnail} alt="thumbnail" />
      </div>
      <div className="user-info">
        <div className="nickname">{nickname}</div>
        <div className="desc-nickname">{nickname}의 블로그입니다.</div>
      </div>
      <div className="blog-info">
        <div className="count-post">
          <span className="post-txt">글</span>
          <span className="post-count">{postCount || 0}</span>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
