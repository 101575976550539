import React from 'react';
import { SettingTemplate } from '../components/settings/SettingTemplate';
import { HeaderContainer } from '../components/base/Header';
import { SettingProfileContainer } from '../components/settings/SettingProfile';

const Settings = () => {
  return (
    <SettingTemplate header={<HeaderContainer />}>
      <SettingProfileContainer />
    </SettingTemplate>
  );
};

export default Settings;
