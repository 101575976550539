import React from 'react';
import './PostLikes.scss';
import { GoHeart } from 'react-icons/go';
const PostLikes = ({ isLike, isFixed, likeCount, onClickPostLike }) => {
  return (
    <div className='PostLikes'>
      <div className={!isFixed ? 'wrap' : 'wrap fixed'}>
        <div className={isLike ? 'likes-wrap liked' : 'likes-wrap'}>
          <button className='like-btn' onClick={onClickPostLike}>
            <GoHeart />
          </button>
          <div className='like-count'>{likeCount || 0}</div>
        </div>
      </div>
    </div>
  );
};

export default PostLikes;
