import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { MainTemplate } from '.';
import { PostListContainer } from '../PostList';
import { HeaderContainer } from '../../base/Header';

@inject('session')
@observer
class MainTemplateContainer extends Component {
  render() {
    const { isSession } = this.props.session;
    if (!isSession) return null;
    return (
      <MainTemplate header={<HeaderContainer />}>
        <PostListContainer />
      </MainTemplate>
    );
  }
}

export default MainTemplateContainer;
