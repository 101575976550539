import React from 'react';
import './EditorHeader.scss';
import { EditorButton } from '../EditorButton';
import ReactFileReader from 'react-file-reader';
import { FaImage } from 'react-icons/fa';
import { IoMdArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';

const EditorHeader = ({ onBack, showSubmitBox, onUploadImage, isEdit }) => {
  return (
    <div className='EditorHeader'>
      <div className='back' onClick={onBack}>
        <IoMdArrowBack></IoMdArrowBack>
        <div>뒤로가기</div>
      </div>
      <Link className='logo' to={'/'}>
        VRWARE Blog
      </Link>
      <div className='editor-btns'>
        <ReactFileReader handleFiles={onUploadImage}>
          <button className='image-upload-btn'>
            <FaImage></FaImage>
            <div>업로드</div>
          </button>
        </ReactFileReader>
        <div className='submit'>
          <EditorButton text={isEdit ? '업데이트' : '저장하기'} onClick={showSubmitBox} />
        </div>
      </div>
    </div>
  );
};

export default EditorHeader;
