import React from 'react';
import './TagTemplate.scss';

const TagTemplate = ({ header, children }) => {
  return (
    <div className='TagTemplate'>
      {header}
      <main>{children}</main>
    </div>
  );
};

export default TagTemplate;
