import { observable, action, computed } from 'mobx';
import { BlogApi } from 'lib/api';
export default class EditorStore {
  @observable title = null;
  @observable markdown = null;
  @observable cursor = null;
  @observable thumbnail = null;
  @observable description = null;
  @observable tags = [];
  @observable isPrivate = false;
  @observable postId = null;
  @observable isEdit = false;
  @observable insertText = null;
  @observable isSubmitBox = false;
  @observable isUploadMask = false;

  //태그 인풋 값
  @observable tagText = '';

  @action
  loadEditPost = postId => {
    if (postId) {
      BlogApi.get(`/posts?postId=${postId}`)
        .then(
          action(post => {
            this.isEdit = true;
            const postData = post.data.data.postList[0];
            this.postId = postData.postId;
            this.title = postData.title;
            this.markdown = postData.content;
            this.description = postData.description;
            this.thumbnail = postData.thumbnail;
            this.tags = postData.tags;
          })
        )
        .catch(err => {
          // console.log(err);
        });
    }
  };

  @action
  showSubmitBox = () => {
    this.isSubmitBox = !this.isSubmitBox;
  };
  @action
  onChangeTitle = title => {
    this.title = title;
  };

  @action
  onChangeTagText = tagText => {
    this.tagText = tagText;
  };

  @action
  onChangeMarkdown = body => {
    const cursor = body.getCursor();
    const markdown = body.getValue();

    this.cursor = cursor;

    if (body !== markdown) {
      this.markdown = markdown;
    }

    if (this.description !== body.getLine(0)) {
      this.description = body.getLine(0);
    }
  };

  @action
  onClickSave = () => {
    if (!localStorage.getItem('globe_user')) return;
    const { title, markdown, thumbnail, description, tags } = this;
    const email = JSON.parse(localStorage.getItem('globe_user')).email;

    if (title && markdown) {
      BlogApi.post('/posts', {
        thumbnail: thumbnail,
        title: title,
        content: markdown,
        tags: tags,
        email: email,
        description: description
      })
        .then(
          action(res => {
            if (res.data.status_code === 200) {
              this.clearPostInfo();
              window.location.href = res.data.data.redirectUrl;
            }
          })
        )
        .catch(err => {
          if (err.response.data.status_code >= 400) {
            return alert('글 저장 실패');
          }
        });
    }
  };

  @action
  onClickUpdate = () => {
    if (!localStorage.getItem('globe_user')) return;
    const { postId, title, markdown, thumbnail, description, tags } = this;
    const email = JSON.parse(localStorage.getItem('globe_user')).email;
    if (postId && title && markdown) {
      BlogApi.patch(`/posts/${postId}`, {
        postId: postId,
        thumbnail: thumbnail,
        title: title,
        content: markdown,
        tags: tags,
        email: email,
        description: description
      })
        .then(
          action(res => {
            if (res.data.status_code === 200) {
              this.clearPostInfo();
              window.location.href = res.data.data.redirectUrl;
            }
          })
        )
        .catch(err => {
          if (err.response.data.status_code >= 400) {
            return alert('글 수정 실패');
          }
        });
    }
  };

  @action
  onUploadImage = files => {
    if (!localStorage.getItem('globe_user')) return;
    if (!files[0]) return;
    if (files[0].size > 1024 * 1024 * 10) return;
    const fileTypeRegex = /^image\/(.*?)/;
    if (!fileTypeRegex.test(files[0].type)) return;
    const data = new FormData();
    data.append('file', files[0]);
    data.append('filename', files[0].name);

    const nickname = JSON.parse(localStorage.getItem('globe_user')).nickname;
    BlogApi.post(`/images/${nickname}`, data)
      .then(res => {
        if (res.data.status_code === 200) {
          let img = `\n![${res.data.data.filename}](${res.data.data.imgPath})\n`;
          this.insertText = img;
          this.setUploadMask(false);
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          this.setUploadMask(false);
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onUploadThumbnail = files => {
    if (!localStorage.getItem('globe_user')) return;
    const data = new FormData();
    data.append('file', files[0]);
    data.append('filename', files[0].name);

    const nickname = JSON.parse(localStorage.getItem('globe_user')).nickname;

    BlogApi.post(`/images/${nickname}`, data)
      .then(res => {
        if (res.data.status_code === 200) {
          if (this.thumbnail !== res.data.data.imgPath) {
            this.thumbnail = res.data.data.imgPath;
          }
          this.setUploadMask(false);
        }
      })
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          this.setUploadMask(false);
          return alert(err.response.data.alert_message);
        }
      });
  };

  @action
  onInsertTag = e => {
    if (e.key !== 'Enter' && e.key !== ',') return;

    //추가할 태그 이름 값
    let tagName = this.tagText;

    //공백 제거
    tagName = tagName.replace(/ +/g, ' ').trim();

    //특수문자 제거
    // eslint-disable-next-line
    var regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
    if (regExp.test(this.tagText)) {
      tagName = this.tagText.replace(regExp, '');
    }

    //같은 값이 있는지 확인
    const isExistTag = this.tags.find(tag => tag === tagName);
    if (isExistTag) {
      e.target.value = '';
      return;
    }

    //엔터일때
    if (e.key === 'Enter' && tagName.length > 0) {
      this.tags.push(tagName);
    }
    //콤마일때
    if (e.key === ',' && tagName.length > 0) {
      if (tagName.slice(0, 1) === ',') {
        //첫번째 문자가 콤마면 콤마를 지움
        e.target.value = '';
      } else {
        //마지막 문자가 콤마면 콤마를 지워서 넣음
        this.tags.push(tagName.slice(0, -1));
      }
    }
    //태그 인풋 값 초기화
    e.target.value = '';
  };

  @action
  onDeleteTag = tagName => {
    const isDelete = this.tags.find(tag => tag === tagName);
    this.tags.splice(this.tags.indexOf(isDelete), 1);
  };

  @action
  onDeleteThumbnail = () => {
    if (!this.thumbnail) return;
    this.thumbnail = null;
  };

  @action
  onClearInsertText = () => {
    this.insertText = null;
  };

  @action
  onClickNewEditor = () => {
    this.isEdit = false;
    this.postId = null;
    this.title = null;
    this.markdown = null;
    this.description = null;
    this.thumbnail = null;
    this.isPrivate = false;
    this.isSubmitBox = false;
    this.tags = [];
  };

  @action
  clearPostInfo = () => {
    this.title = null;
    this.markdown = null;
    this.cursor = null;
    this.thumbnail = null;
    this.description = null;
    this.tags = [];
    this.isPrivate = false;
    this.postId = null;
    this.isSubmitBox = false;
  };

  @action
  setUploadMask = boolean => {
    this.isUploadMask = boolean;
  };

  @computed
  get getThumbnail() {
    return this.thumbnail;
  }

  @computed
  get getIsSubmitBox() {
    return this.isSubmitBox;
  }

  @computed
  get getTags() {
    return this.tags;
  }

  @computed
  get getIsUploadMask() {
    return this.isUploadMask;
  }
}
