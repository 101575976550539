import React, { Component } from 'react';
import PostTags from './PostTags';
import { inject, observer } from 'mobx-react';

@inject('post')
@observer
class PostTagsContainer extends Component {
  render() {
    const { post } = this.props;

    //태그 오름차순 정렬
    const tagList = post.getPostInfo[0].tags.sort();
    return (
      <React.Fragment>
        <PostTags tagList={tagList} />
      </React.Fragment>
    );
  }
}

export default PostTagsContainer;
