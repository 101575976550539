import React from 'react';
import { EditorTemplate } from '../components/editor/EditorTemplate';
import { Helmet } from 'react-helmet';
import queryParser from 'lib/common/queryParser';
import { EditorHeaderContainer } from '../components/editor/EditorHeader';
import { EditorPanesContainer } from '../components/editor/EditorPanes';
import { SubmitBoxContainer } from '../components/editor/SubmitBox';
const Editor = ({ location }) => {
  const postId = queryParser(location.search).post_id;
  return (
    <EditorTemplate header={<EditorHeaderContainer />}>
      <Helmet>
        <title>글 작성하기 | VRWARE Blog</title>
      </Helmet>
      <SubmitBoxContainer />
      <EditorPanesContainer postId={postId} />
    </EditorTemplate>
  );
};

export default Editor;
