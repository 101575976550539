import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import EditorHeader from './EditorHeader';

@inject('editor')
@observer
class EditorHeaderContainer extends Component {
  onClickBack = () => {
    window.history.back();
  };

  render() {
    const { editor } = this.props;
    return (
      <React.Fragment>
        {editor.isEdit && (
          <Helmet>
            <title>수정 중 | VRWARE Blog</title>
          </Helmet>
        )}
        <EditorHeader
          showSubmitBox={editor.showSubmitBox}
          onBack={this.onClickBack}
          onUploadImage={editor.onUploadImage}
          isEdit={editor.isEdit}
        />
      </React.Fragment>
    );
  }
}

export default EditorHeaderContainer;
