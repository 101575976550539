import React from "react";
import "./EditorPanes.scss";

const EditorPanes = ({ left, right, leftRatio, onDivideMouseDown }) => {
  const leftLand = { flex: leftRatio };
  const rightLand = { flex: 1 - leftRatio };
  const devideLand = { left: `${leftRatio * 100}%` };

  return (
    <div className="EditorPanes">
      <div className="panes">
        <div className="pane editor" style={leftLand}>
          {left}
        </div>
        <div className="pane preview" style={rightLand}>
          {right}
        </div>
        <div className="divide" style={devideLand} onMouseDown={onDivideMouseDown} />
      </div>
    </div>
  );
};

export default EditorPanes;
