import React, { Component } from 'react';
import EditorPanes from './EditorPanes';
import { CodeEditorContainer } from '../CodeEditor';
import { EditorPreviewContainer } from '../EditorPreview';
import { inject, observer } from 'mobx-react';

@inject('editor', 'session')
@observer
class EditorPanesContainer extends Component {
  constructor(props) {
    super(props);
    this._onDivideMouseDown = ev => {
      document.body.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseup', this.onMouseUp);
    };

    this.state = {
      leftRatio: 0.5,
      onDivideMouseDown: this._onDivideMouseDown
    };

    //Edit 모드
    if (props.postId) {
      props.editor.loadEditPost(props.postId);
    }
  }
  onMouseMove = ev => {
    this.setState({ leftRatio: ev.clientX / window.innerWidth });
  };

  onMouseUp = ev => {
    document.body.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  };

  render() {
    return (
      <React.Fragment>
        <EditorPanes
          left={<CodeEditorContainer />}
          right={<EditorPreviewContainer />}
          leftRatio={this.state.leftRatio}
          onDivideMouseDown={this.state.onDivideMouseDown}
        />
      </React.Fragment>
    );
  }
}

export default EditorPanesContainer;
