import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './PostCommentItem.scss';
import { dateFormat } from 'lib/common/dateFormat';
import { MarkdownRender } from '../../common/MarkdownRender';
import defaultThumbnail from 'images/base/defaultThumbnail.png';
import { PostCommentInputContainer } from '../PostCommentInput';

class PostCommentItem extends Component {
  state = {
    commentEditContent: this.props.commentInfo.content || '',
    isCommentEdit: false
  };

  onClickCommentUpdate = () => {
    const { commentInfo, onClickCommentUpdate } = this.props;
    const { commentEditContent } = this.state;
    onClickCommentUpdate(commentInfo.commentId, commentEditContent);
    this.onToggleCommentEdit();
  };

  onChangeCommentEditContent = commentEditContent => {
    this.setState({ commentEditContent: commentEditContent });
  };

  onToggleCommentEdit = () => {
    this.setState({
      isCommentEdit: !this.state.isCommentEdit
    });
  };

  render() {
    const { commentInfo, onClickCommentDelete, userNickname } = this.props;
    const { isCommentEdit } = this.state;
    return (
      <div className='PostCommentItem'>
        {!isCommentEdit ? (
          <React.Fragment>
            <div className='comment-header'>
              <Link to={`/@${commentInfo.nickname}`}>
                <img src={commentInfo.thumbnail || defaultThumbnail} alt='thumbnail'></img>
              </Link>
              <div className='comment-user-info'>
                <Link className='nickname' to={`/@${commentInfo.nickname}`}>
                  {commentInfo.nickname}
                </Link>
                <div className='date'>
                  {dateFormat(commentInfo.modDate, 'YYYY년 MM월 DD일')}
                  <div className='modified'>
                    {commentInfo.modDate !== commentInfo.regDate ? '(수정됨)' : null}
                  </div>
                </div>
              </div>
              {commentInfo.nickname === userNickname ? (
                <div className='comment-action-wrap'>
                  <button className='comment-edit' onClick={this.onToggleCommentEdit}>
                    수정
                  </button>
                  <button
                    className='comment-delete-btn'
                    onClick={() => onClickCommentDelete(commentInfo.commentId)}
                  >
                    삭제
                  </button>
                </div>
              ) : null}
            </div>
            <div className='comment-content'>
              <MarkdownRender markdown={commentInfo.content}></MarkdownRender>
            </div>
          </React.Fragment>
        ) : (
          <PostCommentInputContainer
            isCommentEdit={isCommentEdit}
            defaultValue={commentInfo.content || ''}
            onToggleCommentEdit={this.onToggleCommentEdit}
            onClickCommentUpdate={this.onClickCommentUpdate}
            onChangeCommentEditContent={this.onChangeCommentEditContent}
          />
        )}
        {/* //TODO: 리플 추가해야함 */}
      </div>
    );
  }
}

export default PostCommentItem;
