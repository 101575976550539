import React from "react";
import "./SettingTemplate.scss";

const SettingTemplate = ({ header, children }) => {
  return (
    <div className="SettingTemplate">
      {header}
      <main>{children}</main>
    </div>
  );
};

export default SettingTemplate;
