import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { UserCircle } from "components/base/UserCircle";

@inject("base")
@observer
class UserCircleContainer extends Component {
  render() {
    const { base } = this.props;
    return (
      <React.Fragment>
        <UserCircle onClick={base.showUserMenu} thumbnail={base.getThumbnail} />
      </React.Fragment>
    );
  }
}

export default UserCircleContainer;
