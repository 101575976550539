import React from 'react';
import ReactFileReader from 'react-file-reader';
import './UploadThumbnail.scss';
import { IoMdRefresh } from 'react-icons/io';
const UploadThumbnail = ({ thumbnail, onUploadThumbnail, onDeleteThumbnail }) => {
  return (
    <div className='UploadThumbnail'>
      {!thumbnail ? (
        <ReactFileReader handleFiles={onUploadThumbnail}>
          <button className='thumbnail-upload-btn'>
            <div>썸네일 불러오기</div>
          </button>
        </ReactFileReader>
      ) : (
        <div className='thumbnail-preview'>
          <div className='thumbnail-remove'>
            <button onClick={onDeleteThumbnail}>제거</button>
          </div>
          <div className='thumbnail-wrap'>
            <img src={thumbnail} alt='thumbnail' />
            <div className='change-thumbnail'>
              <ReactFileReader handleFiles={onUploadThumbnail}>
                <button>
                  <IoMdRefresh />
                  <div>변경</div>
                </button>
              </ReactFileReader>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadThumbnail;
