import { observable, action, computed } from "mobx";
import { BlogApi } from "lib/api";

export default class MainStore {
  @observable postList = [];
  @observable isLoading = true;

  @action
  getPosts = () => {
    BlogApi.get("/posts")
      .then(
        action(postList => {
          if (postList.data.status_code === 200) {
            this.postList = postList.data.data.postList;
            this.isLoading = false;
          }
        })
      )
      .catch(err => {
        if (err.response.data.status_code >= 400) {
          this.postList = [];
        }
      });
  };

  @computed
  get getPostList() {
    return this.postList;
  }
}
