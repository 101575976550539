import React from 'react';
import './TagPostList.scss';
import { TagPostItem } from '../TagPostItem';
const TagPostList = ({ tagPostList }) => {
  if (!tagPostList) return null;

  return (
    <ul className='PostList'>
      {tagPostList.map(post => (
        <TagPostItem key={post.postLink} post={post} />
      ))}
    </ul>
  );
};

export default TagPostList;
