import React, { Component } from 'react';
import { CodeEditor } from '.';
import marked from 'marked';
import { inject, observer } from 'mobx-react';
import { EditorUploadMask } from '../EditorUploadMask';
import { DropImageContainer } from '../DropImage';

@inject('editor')
@observer
class CodeEditorContainer extends Component {
  onDrop = e => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    const { editor } = this.props;
    editor.onUploadImage(files);
    editor.setUploadMask(false);
  };

  onDragEnter = e => {
    e.preventDefault();
    const { editor } = this.props;
    setImmediate(() => {
      editor.setUploadMask(true);
    });
  };

  onDragLeave = e => {
    e.preventDefault();
    const { editor } = this.props;
    if (!e.relatedTarget) editor.setUploadMask(false);
  };

  onPasteImage = file => {
    if (!file) return;
    const { editor } = this.props;
    const files = [file];
    editor.onUploadImage(files);
  };

  onScroll = e => {
    // retrieve current scroll info and line number
    const scrollInfo = e.getScrollInfo();
    const lineNumber = e.lineAtHeight(scrollInfo.top, 'local');
    const preview = document.getElementById('editor-preview');

    if (!preview) return;
    const down = this.prevScrollTop < scrollInfo.top;
    this.prevScrollTop = scrollInfo.top;

    // content from line 0 -> lineNumber
    const range = e.getRange(
      {
        line: 0,
        ch: null
      },
      {
        line: lineNumber,
        ch: null
      }
    );
    const { top, clientHeight, height } = scrollInfo;

    // scroll to bottom
    if (height - clientHeight - top < 50) {
      preview.scroll({
        behavior: 'smooth',
        top: preview.scrollHeight
      });
      return;
    }

    // count ```
    const count = (range.match(/\n```/g) || []).length;
    const shouldCloseCodeblock = count % 2 === 1 ? '\n```' : '';
    // convert to markdown
    const markdown = `<h1></h1><div>${marked(`${range}${shouldCloseCodeblock}`)}</div>`;
    // create DOM
    const parser = new DOMParser();
    const doc = parser.parseFromString(markdown, 'text/html');
    if (!doc.body) return;
    // count lines
    const totalLines = doc.body.querySelectorAll(
      'p, h1, h2, h3, h4, h5, h6, li, pre, blockquote, hr, table'
    );
    const markdownRender = document.getElementById('markdown-render');
    if (!markdownRender || !preview) return;
    // select all lines
    const elements = markdownRender.querySelectorAll(
      'p, h1, h2, h3, h4, h5, h6, li, pre, blockquote, hr, table'
    );
    if (!elements) return;
    // retrieve scrollTop of rendered current line
    const index = totalLines.length > elements.length ? elements.length : totalLines.length;
    if (!elements[index - 1]) return;
    const { offsetTop } = elements[index - 1];
    // if pointing to same element, calculate diff and apply
    if (this.prevOffsetTop !== offsetTop) {
      this.scrollBefore = scrollInfo.top;
    }
    const diff = this.prevOffsetTop === offsetTop ? scrollInfo.top - this.scrollBefore : 0;
    this.prevOffsetTop = offsetTop;
    const previewScrollTop = offsetTop + diff;
    if (previewScrollTop > this.prevPreviewScrollTop && !down) return;
    this.prevPreviewScrollTop = previewScrollTop;
    // actually scroll
    preview.scroll({
      behavior: 'smooth',
      top: previewScrollTop
    });
  };

  render() {
    const { editor } = this.props;
    return (
      <React.Fragment>
        <CodeEditor
          onChangeMarkdown={editor.onChangeMarkdown}
          onChangeTitle={editor.onChangeTitle}
          title={editor.title}
          markdown={editor.markdown}
          cursor={editor.cursor}
          isEdit={editor.isEdit}
          insertText={editor.insertText}
          onClearInsertText={editor.onClearInsertText}
          onScroll={this.onScroll}
          onPaste={this.onPaste}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
        />
        <DropImageContainer
          onDrop={this.onDrop}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          onPaste={this.onPasteImage}
        />
        <EditorUploadMask isUploadMask={editor.getIsUploadMask} />
      </React.Fragment>
    );
  }
}

export default CodeEditorContainer;
