import React from "react";
import "./UserPostList.scss";
import { UserPostItem } from "../UserPostItem";

const UserPostList = ({ userPostList }) => {
  return (
    <ul className="UserPostList">
      {userPostList.map(post => (
        <UserPostItem key={post.postLink} post={post} />
      ))}
    </ul>
  );
};

export default UserPostList;
