import React from 'react';
import { Link } from 'react-router-dom';
import './UserMenu.scss';

const UserMenu = ({ nickname, onClick, onLogout, onClickNewEditor }) => {
  return (
    <div className="UserMenu" onClick={onClick}>
      <ul className="user-menu-wrap">
        <Link to={`/@${nickname}`}>
          <li className="user-menu-item">내 게시글</li>
        </Link>
        <Link to={`/editor`}>
          <li className="user-menu-item" onClick={onClickNewEditor}>
            새 글 작성
          </li>
        </Link>
        <Link to={`/settings`}>
          <li className="user-menu-item">설정</li>
        </Link>
        <li className="user-menu-item" onClick={onLogout}>
          로그아웃
        </li>
      </ul>
    </div>
  );
};

export default UserMenu;
